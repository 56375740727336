import { navigateToUrl } from 'single-spa';
import {
  getEntity,
  setGlobalConfig,
  getGlobalConfig,
} from '@tapendium/pwa-lib';
import { appendCssLinkToHead } from './append-css-link-to-head';
import { getEntityDetails } from './get-entity';
import { logger } from './tapendium-pwa-root-config';
import { fetchSupportedLanguages } from './i18n';

type Entity = {
  id: string;
  type: string;
};

/**
 * Define global variables
 */
const setupGlobals = ({
  websocketUrl = process.env.PWA_WEBSOCKET_URL,
  apiUrl = process.env.REST_API_URL,
  hostedDomainName = process.env.HOSTED_ZONE_DOMAIN_NAME,
  videosDomain = process.env.VIDEOS_DOMAIN,
} = {}) => {
  setGlobalConfig({
    wsUrl: websocketUrl,
    apiUrl,
    hostedDomainName,
    videosDomain,
  });

  window.addEventListener('single-spa:app-change', async (event) => {
    logger.debug({ event }, 'single-spa:app-change');

    const body = document.querySelector('body');
    // Delegate event listener to body because anchor elements may not be
    // present on the page at the time the app is mounted. Attaching directly
    // to 'a' tags may therefore now work reliably.
    body?.addEventListener('click', (event) => {
      const anchor = (event.target as Element)?.closest('a');
      if (!anchor || !anchor.href) {
        return;
      }
      event.preventDefault();
      navigateToUrl(anchor.href);
    });

    // Use current entity styling if present
    const entity = getEntity();

    await fetchSupportedLanguages(entity?.entityId, entity?.entityType);

    if (entity?.entityId && entity?.entityType) {
      await getEntityChainAndAppendCssLink(entity.entityId, entity.entityType);
    } else {
      const entityRecord = await getEntityDetails();
      logger.debug({ entityRecord });
      const { entityId = '', entityType = '' } = entityRecord ?? {};

      await getEntityChainAndAppendCssLink(entityId, entityType);
    }
  });
};

const getEntityChainAndAppendCssLink = async (
  entityId: string,
  entityType: string
) => {
  try {
    const apiUrl = getGlobalConfig().apiUrl;
    const entityChain: Entity[] = await fetch(
      `${apiUrl}/entities-chains?id=${entityId}&type=${entityType}`
    )
      .then((response) => response.json())
      .catch((error) => {
        console.error('Error:', error);
      });

    entityChain.reverse().forEach((entity: Entity) => {
      appendCssLinkToHead({ entityId: entity.id, entityType: entity.type });
    });
  } catch (error) {
    logger.error(error);
  }
};

export { setupGlobals };
